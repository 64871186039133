import React from 'react'

const CircularBadge = ({ initials, color, size = 'md', style = {} }: any) => {
  const sizeToDimensions = {
    xs: '16px',
    sm: '20px',
    md: '22px',
    lg: '24px',
    xl: '26px',
  } as any

  const sizeToFontSize = {
    xs: '6px',
    sm: '8px',
    md: '9px',
    lg: '10px',
    xl: '11px',
  } as any

  const circleStyle = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: sizeToDimensions[size],
    height: sizeToDimensions[size],
    fontSize: sizeToFontSize[size],
    fontWeight: '450',
    color: '#fff',
    backgroundColor: initials ? color : '#F1F0EF',
    borderRadius: '50%',
    ...style,
  }

  return <div style={circleStyle}>{initials}</div>
}

export default CircularBadge
